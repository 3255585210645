.LibertyFooter {
  flex: 0 0 auto;
  font-size: 14px;
}

.LibertyFooter-pipe {
  color: #fff;
}

.LibertyFooter-container {
  background-color: #1a1446;
  padding: 20pt;
}

.LibertyFooter-lower-container {
  background-color: #fff;
  padding: 10pt;
}

.LibertyFooter-logos {
  padding: 0;
  text-align: center;
  display: inline-block;
  margin: 10px;
  margin-left: 0;
}

.LibertyFooter-icon {
  background-image: url('../Assets/Images/footer-logos.svg');
  background-repeat: no-repeat;
  display: inline-block;
  height: 0;
  width: 0;
}

.LibertyFooter-cybertrust {
  background-position: 0 0;
  height: 50px;
  width: 140px;
}

.LibertyFooter-bbb {
  background-position: -140px 0;
  height: 50px;
  width: 85px;
}

.LibertyFooter-verisign {
  background-position: -225px 0;
  height: 50px;
  width: 95px;
}
